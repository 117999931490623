.card-upload:hover {
    transition: all .25s linear;
    background-color: rgb(203, 234, 234);

    opacity: 0.9;
}

.navbarBlue {
    background-color: rgb(199, 223, 194);

}

.navbarBlue .navbar-text {
    color: #000;

}

.nav-header .brand-title {
    max-width: inherit !important;
    margin-right: 0.9375rem;
}

.nav-header .brand-logo {
    color: #000;
    font-size: calc(1.35rem + 1.2vw);
    font-family: 'poppins', sans-serif;
    font-weight: normal;
}

.homepage h1 {
    font-size: 4rem;
}

.aboutpage h1,
.aboutpage h2,
.aboutpage h3,
.homepage h1,
.homepage h2,
.homepage h3 {
    margin: 1.5rem 0 !important
}

.homepage .card {
    color: #000;
    font-size: 1rem;
}


.homepage .card .bi {
    font-size: 4rem;
}

.homepage .arguments {
    color: rgb(59, 59, 59);
    font-size: 0.9rem;
}

.grisfonce {
    color: rgb(59, 59, 59);
}

.rouge {
    color: #E23428;
}

.homepage .arguments h3 i {
    color: #E23428;
}

.copyright p {
    font-size: 1rem;
    color: #b0b0b0;
    text-align: center;
    font-weight: bold;
}

.navbarBlue {
    font-size: 1rem;
}

.navbarBlue .form-select {
    width: 70%;
    margin-left: 1rem !important;
}

.navbarBlue button.btn {
    font-size: 1rem !important;
}

div.mytip {
    color: #055160;
    background-color: rgb(207, 244, 252);
    border-color: rgb(182, 239, 251);
}

div.fondcard {
    background-color: #fff;
}

i.bigicon {
    font-weight: bold;
    font-size: 1.5rem;
    color: #0d6efd;
}